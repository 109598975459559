
import SearchBar from '../search-bar/SearchBar.vue';
import { trackSearchClick } from '~/pages/search/track';
export default {
  components: {
    SearchBar
  },
  data() {
    return {
      presetWord: ''
    };
  },
  computed: {
    showLeft() {
      if (this.$route.path === '/category') {
        return '';
      } else {
        return 'icon-a-32_icon_performance';
      }
    }
  },
  methods: {
    getContent(item) {
      this.$store.commit('searchHistory/init');
      this.$store.commit('searchHistory/getValue', item);
      this.$router.push({
        query: {
          VisibleFlag: 'replace'
        }
      });
      trackSearchClick(this.$route.path);
    }
  }
};
