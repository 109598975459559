import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c21637fa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=c21637fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c21637fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchMobile: require('/usr/src/app/packages/nuxt/components/search/search-joint/SearchMobile.vue').default,Footer: require('/usr/src/app/packages/nuxt/components/footer/index.vue').default,Footer: require('/usr/src/app/packages/nuxt/components/footer/index.vue').default})
