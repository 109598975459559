
import { mapState } from 'vuex';
import Vue from 'vue';
import throttle from 'lodash.throttle';
import LibBottomBar from '@adidas-com-front/ui-library/src/components/atoms/LibBottomBar/LibBottomBar';
import SearchMobile from '../components/search/search-joint/SearchMobile.vue';
import Footer from '../components/footer';
import { isClient } from '../utilities';
import { parseURL } from '../utilities/helper/urlHelper';
// 解决冲突报错
// import Navigation from '~/components/navigation';
export default Vue.extend({
  name: 'HomeIndex',
  components: {
    Footer,
    // 解决冲突 报错
    // Navigation,
    LibBottomBar,
    SearchMobile
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.textAnimation();

      vm.fromPath = from.fullPath;
    });
  },
  beforeRouteUpdate(to, from, next) {
    // web端未登陆用户在个人信息页面跳转时, 需要调接口的页面需要登陆再跳转, 其他页面直接跳转

    // this.$store.commit('myAccount/SET_SKELETON_STATUS', false);
    if (
      (window.innerWidth > 719 ||
        (window.innerWidth < 719 && from.path === '/my-account')) &&
      !this.$store.state.myAccount.isLogin &&
      !this.$store.state.myAccount.isEmployeeLogin &&
      this.needLoginList.includes(to.path)
    ) {
      this.$login({
        show: true,
        on: {
          login: () => {
            if (this.isNonmemberLogin) {
              this.$store.dispatch('myAccount/afterNonmemberLogin', {
                ctx: this
              });
            } else {
              this.$store.dispatch('myAccount/afterLogin', { ctx: this });
            }
            this.$utils.device.setScrollTop(0);
            next(true);
          }
        }
      });
    } else {
      this.$utils.device.setScrollTop(0);
      next(true);
    }
  },
  data() {
    return {
      currentRoute: '',
      textArr: [],
      linkArr: [],
      number: 0,
      id: 0,
      val: '',
      link: '',
      keepAliveChildProps: {
        include: (isClient)?['MobileCategroy']:[]
      },
      fromPath: '',
      showCartBottomBar: false,
      // 页面底部footer距离页面内容的间隔
      footerMargin: 0,
      links: [
        {
          icon: 'icon-menu_home_32',
          title: '首页',
          to: '/'
        },
        {
          icon: 'icon-menu_catalog_wechat_32',
          title: '分类',
          to: '/category'
        },
        {
          icon: 'icon-menu_shoppingbag_32',
          title: '购物袋',
          to: '/cart',
          showTip: true
        },
        {
          icon: 'icon-menu_profile_32',
          title: '我的',
          to: '/my-account'
        }
      ],
      scrollendY: 0,
      showHeader: true,
      mobileHomePageStyle: {
        position: 'relative',
        top: '48px'
      },
      isMobile: true,
      timer: ''
    };
  },
  computed: {
    ...mapState('homePage', ['hasPreviewPermission', 'componentsData']),
    isNonmemberLogin() {
      return !!this.$store.state.myAccount.isNonmemberLogin;
    },
    showInCategory() {
      if (this.$route.path === '/category') {
        return 'showBottom';
      } else {
        return '';
      }
    },
    changeCategory() {
      if (this.$route.path === '/category') {
        return 'change';
      } else {
        return 'noChange';
      }
    },
    notifications() {
      return this.$store.state.homePage.navInfo.notifications || [];
    },
    showSearch() {
      if (
        this.$route.path &&
        ['/', '/category', '/plp', '/clp'].includes(this.$route.path)
      ) {
        return true;
      } else {
        return false;
      }
    },
    showBottomBar() {
      return this.links.some((item) => item.to === this.$route.path);
    },
    cartNum() {
      return this.$store.state.homePage.navInfo.cartNum;
    },
    computedHomePageLayout() {
      if (this.isMobile) {
        if (this.$route.path === '/') {
          return this.textArr.length > 0 ? { position: 'relative', top: '88px' } : { position: 'relative', top: '48px' };
        } else if (this.$route.path === '/category') {
          return { position: 'relative', top: '48px' };
        } else {
          return {};
        }
      } else if (this.$route.path === '/category') {
        return { position: 'relative', top: '48px' };
      } else {
        return {};
      }
    },
    isCategory() {
      return this.$route.path.startsWith('/category');
    },
    needLoginList() {
      return this.$store.state.myAccount.needLoginList;
    },
    showFooter() {
      if (
        this.$route.path !== '/' ||
        (this.$route.query.preview === '1' && !this.componentsData.length)
      ) {
        return false;
      } else {
        return true;
      }
    },
    showDeeplinkBar() {
      return this.$route.query?.deeplink;
    },
    isRouteActive() {
      return function(item) {
        return this.$route.path === item.to;
      };
    },
    getPath() {
      return function(link) {
        if (!link) return 'javascript:void(0);';
        if (link.startsWith('http')) {
          return link;
        } else {
          const urlPara = this.$router.resolve(parseURL(link));
          return urlPara.href;
        }
      };
    }
  },
  watch: {
    notifications() {
      this.textArr = [];
      this.linkArr = [];
      if (this.notifications.length) {
        for (let i = 0; i < this.notifications.length; i++) {
          const text = this.notifications[i].text;
          this.textArr.push(text);
          const link = this.notifications[i].link
            ? this.notifications[i].link
            : '';
          this.linkArr.push({ i, link });
        }
      }
      this.textAnimation();
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 720;
    if (this.isMobile) {
      // 公告和navigation的高度之和（40 + 48）
      this.footerMargin = 88;
    }
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      this.cartPage();
      // this.footerStyleCompute();
    }, 100);
    const onScroll = (() => {
      return () => {
        const { scrollTop } = this.$utils.device.getScrollTop();
        const startY = scrollTop;
        const scrollEmitter = location.search.includes('deeplink') ? 64 + 88 : 88;
        if (startY >= scrollEmitter && startY >= this.scrollendY) {
          this.showHeader = false;
          this.scrollendY = startY;
        } else {
          this.showHeader = true;
          this.scrollendY = startY;
        }
        if (scrollTop < 2) {
          this.showHeader = true;
        }
      };
    })();
    this.$on(
      'hook:beforeDestroy',
      throttle(this.$utils.device.onPageScroll(onScroll), 300)
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // This method is移动端公告动画
    textAnimation() {
      let ele = '';
      setTimeout(() => {
        ele = document.querySelector('.animation-textContainer');
        if (ele) {
          const boxHeight = ele.clientHeight;
          const len = boxHeight / 18;
          if (len > 1) {
            // 行数大于1时，开启swiper动画。
            let cur = 0;
            ele.style.transform = 'translateY(0)';
            const swpier = (delay) => {
              this.timer = setTimeout(function () {
                cur += 1;
                if (cur >= len) {
                  cur = 0;
                  ele.style.transition = 'none';
                  ele.style.transform = 'translateY(0)';
                  clearTimeout(this.timer);
                  swpier(5000);
                } else {
                  const top = -18 * cur;
                  ele.style.transform = 'translateY(' + top + 'px)';
                  ele.style.transition =
                    'all 0.1s cubic-bezier(0.3, 0, 0.45, 1)';
                  clearTimeout(this.timer);
                  swpier(5000);
                }
              }, delay);
            };
            clearTimeout(this.timer);
            swpier(5000);
          }
        }
      });
    },
    handleResize() {
      clearTimeout(this.timer);
      this.textAnimation();
      this.isMobile = window.innerWidth < 720;
      if (this.isMobile) {
        this.footerMargin = 88;
      } else {
        this.footerMargin = 0;
      }
    },
    cartPage() {
      if (this.$route.path.startsWith('/cart')) {
        if (this.$route.query.tab) {
          this.showCartBottomBar = true;
        } else {
          this.showCartBottomBar = false;
        }
      }
    },
    footerStyleCompute() {
      if (isClient) {
        // 获取header的高度
        const pcHeaderHeight =
          document &&
          document.getElementsByClassName('pc-navigation-bar')[0].offsetHeight;
        // 获取当前分辨率网页的可视总高
        const clientHeight = document && document.body.clientHeight;
        // 获取当前页面内容的高度
        const rootHeight =
          document &&
          document.getElementsByClassName('container-payment')[0].offsetHeight;
        // 获取当前页脚的高度
        const footerHeight =
          document && document.getElementsByTagName('footer')[0].offsetHeight;
        // 如果页面整体内容高度小于可视高度，则将footer置于最底部
        if (rootHeight + pcHeaderHeight + footerHeight < clientHeight) {
          this.footerMargin =
            clientHeight - rootHeight - pcHeaderHeight - footerHeight;
        }
      }
    }
  }
});
