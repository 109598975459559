
import { useResizeDetector } from './ResizeDetector';

export default {
  name: 'LibBottomBar',
  props: {
    autoRefresh: { type: Boolean },
    watchData: { type: Boolean },
  },
  data() {
    return {
      height: 0, // 默认高度
    };
  },
  computed: {
    // 计算bottombar 高度
    styles() {
      return {
        height: this.height + 'px',
      };
    },
  },
  mounted() {
    this.refresh();

    this.$watch(
      () => this.watchData,
      () => this.refresh()
    );

    if (this.autoRefresh) {
      // 监听视口和元素变化，响应刷新事件
      useResizeDetector({
        context: this,
        elGetter: () => this.$refs.content,
        onResize: () => this.refresh(),
      });
    }
  },
  methods: {
    refresh() {
      this.height = this.$refs.content.offsetHeight;
    },
  },
};
